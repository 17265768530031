import React from "react"
import { Facebook, Twitter } from "react-feather"
import { graphql } from "gatsby"
import { Layout } from "../../components/Layout"
import { AffiliateList } from "../../components/AffiliateList"
import { SEO } from "../../components/SEO"
import styles from "./blog.module.css"
import containerClasses from "../../styles/container.module.css"
import { CTABlock } from "../../components/CTABlock"

export default function Template({ data }) {
  const domain = data.site.siteMetadata.domain
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const {
    date,
    title,
    path,
    author,
    authorImage,
    category,
    image,
    imageAlt,
    imageTitle,
    seoTitle,
    metaDescription,
  } = frontmatter

  const isSSR = typeof window === "undefined"

  if (!isSSR) {
    const fbShare = () => {
      return ((d, s, id) => {
        const fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        const js = d.createElement(s)
        js.id = id
        js.src =
          "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0"
        fjs.parentNode.insertBefore(js, fjs)
      })(document, "script", "facebook-jssdk")
    }

    const twitterShare = () => {
      window.twttr = (function(d, s, id) {
        const fjs = d.getElementsByTagName(s)[0]
        const t = window.twttr || {}
        if (d.getElementById(id)) return t
        const js = d.createElement(s)
        js.id = id
        js.src = "https://platform.twitter.com/widgets.js"
        fjs.parentNode.insertBefore(js, fjs)

        t._e = []
        t.ready = function(f) {
          t._e.push(f)
        }

        return t
      })(document, "script", "twitter-wjs")
    }

    twitterShare()
    fbShare()
  }

  return (
    <Layout>
      <SEO title={seoTitle || title} description={metaDescription} />
      <div className="bg-white">
        <div
          className={`${containerClasses.container} py-12 md:py-16 lg:py-26`}
        >
          <div className="col-span-4 md:col-span-8 lg:col-start-2 lg:col-span-10">
            <div className="col-span-12 lg:px-8 lg:col-span-8 lg:col-start-3">
              <p className="mb-0 tag">{category}</p>
              <h1 className="fluid-display-02">{title}</h1>
              <div className="flex mt-6">
                <div className="w-10 h-10 overflow-hidden rounded-full">
                  <img loading="lazy" src={authorImage} alt="Author profile" />
                </div>
                <div className="ml-4 text-left">
                  <p className="mb-0 mb-1 font-sans text-base font-semibold">
                    {author}
                  </p>
                  <p className="mb-0 text-xs leading-none">{date}</p>
                </div>
                <div className="lg:col-span-8 lg:col-start-3 lg:hidden"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="headerHalfAndHalf">
        <div className={containerClasses.container}>
          <div className="col-span-4 md:col-span-8 lg:col-start-2 lg:col-span-10">
            <img
              loading="lazy"
              className="w-full"
              src={image}
              alt={imageAlt}
              title={imageTitle}
            />
          </div>
        </div>
      </div>
      <div className="bg-white2">
        <div className={`${containerClasses.container} pt-16 py-8`}>
          <div className="col-span-4 md:col-span-8 lg:col-start-3 lg:col-span-8">
            <div
              className={`${styles.blogContent} lg:col-span-8 lg:col-start-3 xl:col-span-6 xl:col-start-4 `}
              dangerouslySetInnerHTML={{ __html: html }}
            />
            <div className="flex items-center mt-4">
              <div className="relative flex items-center justify-center w-10 h-10 mr-2 overflow-hidden rounded-full text-onDarkHighEmphasis bg-mainBrand-8">
                <div className="absolute top-0 z-20 flex items-center justify-center w-10 h-10 pointer-events-none bg-blackNew">
                  <Facebook size="18" />
                </div>
                <div
                  className="z-10 fb-share-button"
                  data-href={`${domain}${path}`}
                  data-layout="button"
                  data-show-count="false"
                >
                  Share to Facebook
                </div>
              </div>
              <a
                href="https://twitter.com/share?ref_src=twsrc%5Etfw"
                className="flex items-center justify-center w-10 h-10 rounded-full bg-blackNew text-onDarkHighEmphasis"
                data-show-count="false"
              >
                <span className="sr-only">Tweet</span>
                <Twitter size="18" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <CTABlock />
      <AffiliateList />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        id
        date(formatString: "MMMM DD, YYYY")
        title
        path
        author
        category
        authorImage
        image
        imageAlt
        imageTitle
        seoTitle
        metaDescription
      }
    }
    site {
      siteMetadata {
        domain
      }
    }
  }
`
